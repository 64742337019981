import { Button, Col, Modal, Row, message, notification } from 'antd'
import React, { useContext, useState } from 'react'
import {
    CheckCircleFilled, CheckSquareOutlined,
    CloseSquareOutlined, EyeOutlined
} from '@ant-design/icons'
import useSigner from 'hooks/useSigner'
import API, { baseUrl } from 'api/API'
import { ListCtx } from 'context/ListCtx'

const Documento = ({ documento, operacion }) => {
    const { confirm } = Modal
    const { firmarDocumento } = useSigner()
    const { getOperaciones } = useContext(ListCtx)

    const [signing, setSigning] = useState(false)
    const [rejecting, setRejecting] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const verDocumento = async () => {
        try {
            // Documento ya firmado
            if (documento?.enlace_archivo !== "-") {
                window.open(`${documento?.enlace_archivo}`, '_blank')
                // Descargar preview de documento a firmar
            } else {
                setDownloading(true)
                const { data, headers } = await API.get(`${documento?.enlace_descargable_api_key}?operacion_pk=${operacion?.operacion_pk}`, {
                    responseType: 'blob',
                })
                const type = headers['content-type']
                const blob = new Blob([data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${documento?.documento || 'archivo'}.pdf`
                link.click()
                notification.open({
                    message: `${documento?.documento || 'archivo'}.pdf`,
                    description: 'Se ha descargado el archivo.',
                    placement: "topRight",
                    type: 'success',
                });
            }
        } catch (error) {
            message.error('No se pudo obtener el documento.')
        } finally {
            setDownloading(false)
        }
    }

    const rechazar = () => {
        confirm({
            title: '¿Seguro que deseas cancelar la negociación de esta operación?',
            content: 'Esta operación y sus títulos relacionados serán rechazados.',
            centered: true,
            icon: <></>,
            keyboard: true,
            style: {
                fontFamily: 'Poppins',
            },
            onOk() {
                setRejecting(true)
                API.post(`rechazar-operacion-emisor/`, {
                    operacion: operacion?.operacion_pk
                })
                    .then(() => {
                        getOperaciones()
                        message.success(`La Operación ${operacion?.numero_operacion} ha sido rechazada.`)
                    })
                    .catch(() => {

                    }).finally(() => setRejecting(false))
            },
            okButtonProps: {
                loading: rejecting,
                style: {
                    backgroundColor: '#E04F3C',
                    borderColor: '#E04F3C',
                    color: '#FFF',
                    fontFamily: 'Poppins',
                    marginInlineStart: '0',
                }
            },
            cancelButtonProps: {
                style: {
                    backgroundColor: 'white',
                    borderColor: '#E04F3C',
                    color: '#E04F3C',
                    fontFamily: 'Poppins',
                }
            },
            okText: 'Sí, estoy seguro',
            cancelText: 'No, continuar'
        })
    }

    return (
        <Row gutter={[8, 8]}>
            <Col xxl={15} xl={10} lg={10} md={24} sm={24} xs={24}>
                <div
                    className='titulo-documento'
                    style={{
                        backgroundColor: documento?.firmado ? '#e6f1e8' : '#f1f1f1',
                    }}
                >
                    <CheckCircleFilled style={{
                        marginRight: '5px',
                        color: documento?.firmado ? '#32b050' : '#b8b8b8',
                    }} />
                    {documento?.documento}
                </div>
            </Col>
            <Col xxl={9} xl={14} lg={14} md={24} sm={24} xs={24}>
                <Row gutter={[8, 8]}>
                    <Col md={8} sm={24} xs={24}>
                        <Button
                            className="boton-adjuntar"
                            disabled={(documento?.puede_firmar === false && documento?.firmado === false)}
                            icon={<EyeOutlined />}
                            loading={downloading}
                            onClick={verDocumento}
                        >
                            Ver
                        </Button>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                        <Button
                            className="boton-confirmar"
                            disabled={documento?.firmado || documento?.puede_firmar === false}
                            icon={<CheckSquareOutlined />}
                            loading={signing}
                            onClick={() => firmarDocumento(documento?.pk_doc, operacion?.operacion_pk, getOperaciones, documento?.tipologia, setSigning)}
                        >
                            {documento?.firmado ? 'Firmado' : 'Firmar'}
                        </Button>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                        <Button
                            className="boton-rechazar"
                            disabled={operacion?.soportes?.filter(el => el?.firmado === false)?.length === 0 || documento?.puede_firmar === false}
                            icon={<CloseSquareOutlined />}
                            onClick={() => rechazar()}
                        >
                            Rechazar
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Documento