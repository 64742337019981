import React, { createContext, useState } from 'react'

export const SignerContext = createContext()

const SignerCtxProvider = ({ children }) => {
    const [signer, setSigner] = useState({
        modalOpen: false,
        loading: false,
        proceed: null,
        cancel: null
    })

    return (
        <SignerContext.Provider value={[
            signer,
            setSigner,
        ]}>
            {children}
        </SignerContext.Provider>
    )
}

export default SignerCtxProvider