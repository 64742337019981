import { Button, Col, Row, Spin } from 'antd'
import React, { useContext, useEffect } from 'react'
import logoliqui from 'assets/logoliquitech.png'
import Operacion from 'components/Operacion'
import Error from 'components/Error'
import { ListCtx } from 'context/ListCtx'
import Empty from 'components/Empty'

const Main = () => {
    const { err, loading, operaciones, getOperaciones } = useContext(ListCtx)

    useEffect(() => {
        getOperaciones();
    }, [])

    return (
        <div className='bg'>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
                <Col xs={23} sm={23} md={18} lg={18} xl={18} xxl={18}>
                    <div className='content-wrapper'>
                        <img
                            alt='Liquitech Logo'
                            className='logoliqui'
                            src={logoliqui}
                        />
                        <h2 style={{ textAlign: 'center' }}>
                            Firma aquí tus operaciones
                        </h2>
                        <span className='texto-explicativo'>
                            Ingresa a tus operaciones para firmar los documentos correspondientes
                        </span>
                        <div className='area-documentos'>
                            <Spin
                                size='large'
                                spinning={loading}
                            >

                            </Spin>
                            {err ? (
                                <Error />
                            ) : (
                                <>
                                    {operaciones?.length === 0 ? (
                                        !loading && <Empty />
                                    ) : (
                                        <>
                                            {operaciones?.map((el, i) => (
                                                <Operacion key={i} operacion={el} />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Main