import { Result, Space } from 'antd'
import React from 'react'

const Error = () => {
    return (
        <Space className='error-container'>
            <Result
                title="Ha ocurrido un error."
                subTitle="No pudimos encontrar tus documentos pendientes."
                status="error"
            />
        </Space>
    )
}

export default Error