import './App.css';
import Main from 'pages/Main';
import { ConfigProvider, Spin } from 'antd';
import SignerCtxProvider from 'context/SignerCtx';
import ModalToken from 'components/ModalToken';
import ModalPreview from 'components/ModalPreview';
import ListCtxProvider from 'context/ListCtx';
import { ReactComponent as LoadingLiquiIcon } from 'assets/loading_liquitech.svg'

Spin.setDefaultIndicator(<LoadingLiquiIcon />)

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F9AC00',
          fontFamily: 'Poppins',
        }
      }}
    >
      <ModalPreview />
      <ListCtxProvider>
        <SignerCtxProvider>
          <ModalToken />
          <Main />
        </SignerCtxProvider>
      </ListCtxProvider>
    </ConfigProvider>
  );
}

export default App;
