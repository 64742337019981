import { Button, Modal } from 'antd'
import usePreview from 'hooks/usePreview'
import React from 'react'

const ModalPreview = () => {
    const { link, visible, setVisible } = usePreview()

    return (
        <Modal
            centered
            className='modal-preview'
            closable={false}
            footer={null}
            keyboard={true}
            open={visible}
            width={500}
        >
            <iframe
                className='preview-frame'
                src={link}
                title='Preview'
            />
            <div className='btn-preview-container'>
                <Button
                    className='btn-preview-cerrar'
                    onClick={() => setVisible(false)}
                >
                    Cerrar
                </Button>
            </div>
        </Modal>
    )
}

export default ModalPreview