import React from 'react'
import Documento from './Documento'
import { Collapse } from 'antd'
import { CheckCircleFilled, DoubleRightOutlined } from '@ant-design/icons'

const Operacion = ({ operacion }) => {
    const getIcon = ({ isActive }) => {
        if (operacion?.soportes?.filter(el => el?.firmado === false)?.length === 0) {
            return <CheckCircleFilled style={{ color: 'green', fontSize: '18px' }} />
        } else {
            return <DoubleRightOutlined rotate={isActive ? 270 : 90} />
        }
    }

    return (
        <Collapse
            className='operacion-collapse'
            expandIcon={getIcon}
            expandIconPosition='end'
            items={[
                {
                    key: '1',
                    label: `Operación ${operacion?.numero_operacion}`,
                    children: <div className='doc-container'>
                        {operacion?.soportes?.map((el, i) => (
                            <Documento
                                documento={el}
                                key={i}
                                operacion={operacion}
                            />
                        ))}
                    </div>,
                },
            ]}
            style={{
                backgroundColor: operacion?.soportes?.filter(el => el?.firmado === false)?.length === 0 ? '#e5f1e8' : 'white'
            }}
        />
    )
}

export default Operacion