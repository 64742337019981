import { useContext } from 'react'
import { SignerContext } from 'context/SignerCtx'
import API from 'api/API'
import { message } from 'antd'

const useSigner = () => {
    const [signer, setSigner] = useContext(SignerContext)

    const firmarDocumento = async (tipoDocumento, pkOperacion = null, onFinish, tipologia, destinatario = 1, setSigning = () => {}) => {
        try {
            // Solicitar token
            setSigning(true)
            setSigner({
                ...signer,
                loading: true,
            })
            const params = new URLSearchParams(window.location.search)
            const emisor_nit = params.get('nitEmisor')
            const resp = await API.post(`solicitar-token-firma-emisor-apikey/`, { destinatario, emisor_nit, tipologia })

            // Abrir modal para ingresar token
            const promise = new Promise((resolve, reject) => {
                setSigner({
                    modalOpen: true,
                    loading: false,
                    proceed: resolve,
                    cancel: reject,
                })
            })
            return promise.then(
                // Token válido, proceder con firma
                async () => {
                    setSigner({
                        ...signer,
                        modalOpen: true,
                        loading: true,
                    })
                    let payload = {
                        id_documento: Number.parseInt(tipoDocumento),
                        emisor_nit,
                    }
                    if (pkOperacion) {
                        payload = {
                            ...payload,
                            operacion: Number.parseInt(pkOperacion)
                        }
                    }
                    API.post(`firmar-documento-emisor-apikey/`, payload)
                        .then(() => {
                            onFinish()
                            setSigner({
                                ...signer,
                                modalOpen: false,
                                loading: false,
                            })
                            message.success('Firmado con éxito.')
                        }).catch(() => {
                            message.error('No se pudo firmar este documento.')
                        })
                    setSigning(false)
                    return true;
                },
                // Modal cerrado por el usuario
                () => {
                    setSigner({ ...signer, modalOpen: false });
                    setSigning(false)
                    return false;
                }
            );
        } catch (error) {
            message.error('No se pudo solicitar el token.')
            setSigner({
                ...signer,
                loading: false,
            })
        }
    }

    return {
        ...signer,
        firmarDocumento,
    }
}

export default useSigner