import React, { createContext, useState } from 'react'

export const PreviewCtx = createContext({})

const PreviewCtxProvider = ({ children }) => {
    const [visible, setVisible] = useState(false)
    const [link, setLink] = useState("")

    return (
        <PreviewCtx.Provider value={{
            visible,
            setVisible,
            link,
            setLink
        }}>
            {children}
        </PreviewCtx.Provider>
    )
}

export default PreviewCtxProvider