import React, { useEffect, useState } from "react";
import useSigner from 'hooks/useSigner'
import { Button, Form, Input, Modal, Spin, message } from "antd";
import API from "api/API";

const ModalToken = () => {
    const { modalOpen, proceed, cancel, loading } = useSigner();

    const [form] = Form.useForm()

    const [validando, setValidando] = useState(false)

    const validarToken = async (data) => {
        try {
            setValidando(true)
            const params = new URLSearchParams(window.location.search)
            const emisor_nit = params.get('nitEmisor')
            const response = await API.post(`validar-token-firma-emisor-apikey/`, {
                ...data,
                emisor_nit
            })
            if (response?.data) {
                proceed()
                form.resetFields()
            }
        } catch (error) {
            message.error('No se pudo validar el token.')
        } finally {
            setValidando(false)
        }
    }

    return (
        <Modal
            centered
            closable={true}
            footer={null}
            maskClosable={false}
            onCancel={cancel}
            open={modalOpen}
            title='Firma electrónica'
        >
            <Spin spinning={loading}>
                <p>Se ha enviado un token al correo electrónico y al celular de la persona que aparece en el documento a firmar. 
                    Por favor ingrese este token para continuar con su firma electrónica.</p>
                <Form form={form} onFinish={validarToken} validateTrigger="onSubmit">
                    <Form.Item
                        name='token'
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                        ]}
                    >
                        <Input placeholder='Ingresar token' />
                    </Form.Item>
                    <Button
                        className="btn-firmar-electronicamente"
                        type="primary"
                        htmlType='submit'
                        loading={validando || loading}
                    >
                        Firmar electrónicamente
                    </Button>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ModalToken