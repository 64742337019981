import API from 'api/API'
import React, { createContext, useState } from 'react'

export const ListCtx = createContext()

const ListCtxProvider = ({ children }) => {
    const [err, setErr] = useState(false)
    const [loading, setLoading] = useState(true)
    const [operaciones, setOperaciones] = useState([])

    const getOperaciones = async () => {
        try {
            const params = new URLSearchParams(window.location.search)
            setLoading(true)
            setErr(false)
            // const { data } = await API(`app_operaciones/operaciones/operaciones-pendientes-firma/?nit_emisor=114089513`)
            const { data } = await API(`operacion-pendientes-firma/?nit_emisor=${params.get('nitEmisor')}`)
            setOperaciones(data)
        } catch (error) {
            setErr(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ListCtx.Provider value={{
            loading,
            setLoading,
            operaciones,
            setOperaciones,
            getOperaciones,
            err
        }}>
            {children}
        </ListCtx.Provider>
    )
}

export default ListCtxProvider