import { Button, Result, Space } from 'antd'
import React from 'react'

const Empty = () => {
    return (
        <Space className='error-container'>
            <Result
                title="No tienes ningún documento pendiente por firmar."
                status="success"
                extra={
                    <div className='btn-finalizar-container'>
                        <Button className='btn-finalizar'>
                            Finalizar
                        </Button>
                    </div>
                }
            />
        </Space>
    )
}

export default Empty